import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import WalletIcon from '../assets/imgs/wallet.png';
import FragmentIcon from '../assets/imgs/fragment.png';
import SwapIcon from '../assets/imgs/swap.png';
import Elipse4 from '../assets/imgs/Ellipse 4.png';
function CarbonEcosystem() {
	return (
		<Container className=' px-3 px-md-7 mt-10' fluid>
			<img
				src={Elipse4}
				alt=''
				className='img-fluid elipse4'
				width='100%'
			/>
			<div className='px-0 px-lg-10 text-center'>
				<h2 className='carbon_ecosystem-title'>
					Carbon Credit Ecosystem
				</h2>
				<p className='carbon_ecosystem-desc mt-5'>
					Climate change and global warming are the significant
					challenges of the new century. A viable solution to mitigate
					greenhouse gas emissions is via a globally incentivized
					market mechanism. In this view, the carbon dioxide (or other
					greenhouse gases) emission is considered a commodity,
					forming a carbon trading system.
				</p>
				<h4 className='carbon_ecosystem-subheading mt-8'>
					The main challenges of current systems
				</h4>
			</div>
			<Row className='mt-5 justify-content-center'>
				<Col lg='4' md='6'>
					<div className='carbon_ecosystem__card text-center'>
						<img
							src={WalletIcon}
							alt='wallet'
							className='img-fluid'
						/>
						<h5 className='mt-6 carbon_ecosystem__card-desc'>
							Lack of transparency leads to vercrediting and
							double-spending.
						</h5>
					</div>
				</Col>
				<Col lg='4' md='6' className='mt-4 mt-md-0'>
					<div className='carbon_ecosystem__card text-center'>
						<img
							src={FragmentIcon}
							alt='fragment'
							className='img-fluid'
						/>
						<h5 className='mt-6 carbon_ecosystem__card-desc'>
							Fragmented <br />
							Implementations
						</h5>
					</div>
				</Col>
				<Col lg='4' md='6' className='mt-4 mt-lg-0'>
					<div className='carbon_ecosystem__card text-center'>
						<img src={SwapIcon} alt='swap' className='img-fluid' />
						<h5 className='mt-6 carbon_ecosystem__card-desc'>
							Substantial transaction costs that transfer wealth
							to brokers and agents.
						</h5>
					</div>
				</Col>
			</Row>
			<p className='carbon_ecosystem-desc text-center mt-7 px-0 px-lg-10'>
				We aim to create a Carbon Credit Ecosystem using smart contracts
				that operate with blockchain technology to bring more
				transparency, accessibility, liquidity, and standardization to
				carbon markets. <br /> <br />
				<br /> This Ecosystem includes a tokenization mechanism to
				securely digitize carbon credits with clear minting and burning
				protocols, a transparent mechanism for distributing tokens, and
				tools to engage all stakeholders, including the energy industry,
				project verifiers, liquidity providers, NGOs, concerned
				citizens, and governments.
			</p>
		</Container>
	);
}

export default CarbonEcosystem;
