import React, { useEffect, useRef } from 'react';

function EnergyConsumption({ setActiveCard }) {
	const energyRef = useRef();
	const handleClickOutside = e => {
		if (!energyRef.current?.contains(e.target)) {
			setActiveCard(-1);
		}
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () =>
			document.removeEventListener('mousedown', handleClickOutside);
	});
	return (
		<div className='heading-card__active' ref={energyRef}>
			<i
				className='fa fa-arrow-left fa-2x text-white pointer'
				onClick={() => {
					setActiveCard(-1);
				}}
			></i>
			<h2 className='mt-5'>
				Energy Consumption <br />& Useage
			</h2>
			<p className='mt-5'>
				A 2018 study by the University of Pittsburgh found that, on
				average, proof-of-work blockchains consumed more than three
				times as much energy as proof-of-stake blockchains. We aim to
				make the PERFUEL blockchain as low energy consumption as
				possible but without sacrificing security.
			</p>
		</div>
	);
}

export default EnergyConsumption;
