import React, { useEffect, useRef } from 'react';

function CurrentChallenge({ setActiveCard }) {
	const currentChallengeRef = useRef();
	const handleClickOutside = e => {
		if (!currentChallengeRef.current?.contains(e.target)) {
			setActiveCard(-1);
		}
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () =>
			document.removeEventListener('mousedown', handleClickOutside);
	});
	return (
		<div className='heading-card__active' ref={currentChallengeRef}>
			<i
				className='fa fa-arrow-left fa-2x text-white pointer'
				onClick={() => {
					setActiveCard(-1);
				}}
			></i>
			<h2 className='mt-5'>Current Challenges</h2>
			<p className='mt-5'>
				Substantial transaction costs in transferring wealth to brokers
				and agents are a significant problem with the current carbon
				trading markets. Traders and brokers often get a commission of 3
				to 8 percent of the value of the credit (with an industry
				average of 5 percent). Furthermore, these schemes rely on
				third-party verifiers to check claims and often are paid by
				project developers, meaning they have the incentive to approve
				all clean projects they investigate. The confluence of these
				factors may explain why global (and regional) carbon markets
				have failed to make a meaningful difference in mitigating
				greenhouse gas emissions.
			</p>
		</div>
	);
}

export default CurrentChallenge;
