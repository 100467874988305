import React, { useState } from 'react';
import { Collapse, Container } from 'reactstrap';
function Benefits() {
	const [activeTab, setActiveTab] = useState(1);
	const toggleTab = tab => {
		if (tab === activeTab) {
			setActiveTab(-1);
		} else {
			setActiveTab(tab);
		}
	};
	return (
		<Container className='text-center px-2 px-md-5' fluid>
			<div className='px-0 px-lg-10 mt-8'>
				<h2 className='heading'>
					What is the benefit of a{' '}
					<br className='d-none d-lg-block' /> cryptocurrency backed
					by gold?
				</h2>
				<p className='mb-0 mt-5 carbon_ecosystem-desc'>
					The benefits of gold-backed crypto are numerous and are
					primarily linked to its stability compared to other options
					like Bitcoin or the Ethereum blockchain. We've listed a few
					of the most commonly cited benefits below:
				</p>
			</div>
			<div
				className='collapse-card pointer mt-8'
				onClick={() => {
					toggleTab(1);
				}}
			>
				<div className='d-flex align-items-center'>
					<h2 className='mb-0'>It's a stable option</h2>
					{activeTab === 1 ? (
						<i className='fa fa-arrow-up text-site-primary ml-auto fa-2x'></i>
					) : (
						<i className='fa fa-arrow-down text-site-primary ml-auto fa-2x'></i>
					)}
				</div>
				<Collapse isOpen={activeTab === 1}>
					<p className='mb-0 mt-7 text-left'>
						As mentioned, a legitimate gold-backed cryptocurrency
						enjoys a higher level of market stability than its more
						volatile counterparts. This is because it's
						intrinsically linked to the current gold price, which is
						one of the most stable markets. Historically, everyone
						wants precious metals, and so a coin related to those
						metals is bound to retain its value as long as it's
						associated with these materials.
					</p>
				</Collapse>
			</div>
			<div
				className='collapse-card pointer mt-4'
				onClick={() => {
					toggleTab(2);
				}}
			>
				<div className='d-flex align-items-center'>
					<h2 className='mb-0'>
						It's easier to understand the market
					</h2>
					{activeTab === 2 ? (
						<i className='fa fa-arrow-up text-site-primary ml-auto fa-2x'></i>
					) : (
						<i className='fa fa-arrow-down text-site-primary ml-auto fa-2x'></i>
					)}
				</div>
				<Collapse isOpen={activeTab === 2}>
					<p className='mb-0 mt-7 text-left'>
						Tied to this stability, the price fluctuations of
						gold-backed crypto, as a whole, are easier to
						understand. Many market variations of Bitcoin and other
						crypto tokens can seem random, even arbitrary. However,
						with these stablecoins, you can look at the daily gold
						market and see trends, changes and predictions that will
						help to make informed investment decisions
					</p>
				</Collapse>
			</div>
			<div
				className='collapse-card pointer mt-4'
				onClick={() => {
					toggleTab(3);
				}}
			>
				<div className='d-flex align-items-center'>
					<h2 className='mb-0'>Cryptocurrency is easy to store</h2>
					{activeTab === 3 ? (
						<i className='fa fa-arrow-up text-site-primary ml-auto fa-2x'></i>
					) : (
						<i className='fa fa-arrow-down text-site-primary ml-auto fa-2x'></i>
					)}
				</div>
				<Collapse isOpen={activeTab === 3}>
					<p className='mb-0 mt-7 text-left'>
						Unless you have a Swiss vault (or several) to hand, it's
						not easy to store large volumes of gold on an individual
						level. Digitalized gold and silver allow investors to
						take advantage of their value for trading, investing and
						spending without worrying about their physical location.
						This can translate to lower fees for using it as a
						trading asset, leading to greater convenience and
						profit.
					</p>
				</Collapse>
			</div>
			<div
				className='collapse-card pointer mt-4'
				onClick={() => {
					toggleTab(4);
				}}
			>
				<div className='d-flex align-items-center'>
					<h2 className='mb-0'>
						You can access blockchain trading apps
					</h2>
					{activeTab === 4 ? (
						<i className='fa fa-arrow-up text-site-primary ml-auto fa-2x'></i>
					) : (
						<i className='fa fa-arrow-down text-site-primary ml-auto fa-2x'></i>
					)}
				</div>
				<Collapse isOpen={activeTab === 4}>
					<p className='mb-0 mt-7 text-left'>
						By tokenizing gold and silver into digital assets,
						holders can access blockchain trading platforms and all
						of their associated benefits with a tangible asset value
						behind them. These platforms offer easy trading, strict
						security
					</p>
				</Collapse>
			</div>
			<div
				className='collapse-card pointer mt-4'
				onClick={() => {
					toggleTab(5);
				}}
			>
				<div className='d-flex align-items-center'>
					<h2 className='mb-0'>
						It avoids central bankers and, thus, banks
					</h2>
					{activeTab === 5 ? (
						<i className='fa fa-arrow-up text-site-primary ml-auto fa-2x'></i>
					) : (
						<i className='fa fa-arrow-down text-site-primary ml-auto fa-2x'></i>
					)}
				</div>
				<Collapse isOpen={activeTab === 5}>
					<p className='mb-0 mt-7 text-left'>
						Through the blockchain trading methods mentioned above,
						investors can transfer value without going to a bank.
						This is beneficial in various ways: it's faster, more
						accessible, and allows you to avoid fluctuations when
						you trade money globally. In short, it's an excellent
						way to beat a lousy exchange rate
					</p>
				</Collapse>
			</div>
		</Container>
	);
}

export default Benefits;
