import React, { useState } from 'react';
import { Container, Row, Col, Collapse } from 'reactstrap';
import CurrentChallenge from './Collapses/CurrentChallenge';
import EnergyConsumption from './Collapses/EnergyConsumption';
import SmartContract from './Collapses/SmartContract';
function CarbonCreditEcosystem() {
	const [activeCard, setActiveCard] = useState(-1);
	return (
		<Container className=' px-3 px-md-7 mt-10' fluid>
			<div className='px-0 px-lg-10 text-center'>
				<h2 className='heading'>
					A Blockchain-based Carbon Credit Ecosystem
				</h2>
				<p className='carbon_ecosystem-desc mt-5'>
					PERFUEL aims to create a Carbon Credit Ecosystem on
					Blockchain to bring more liquidity, transparency,
					accessibility, and standardization to carbon markets. This
					Ecosystem includes all stakeholders, a tokenization
					mechanism with clear minting and burning protocols and
					transparent distribution of tokens. Our PERFUEL token will
					transfer held carbon credits to the blockchain by converting
					them into digital tokens distributed to holders of our
					native token. The price will be determined by market
					dynamics driven by supply and demand.
				</p>
			</div>
			<Row className='mt-8 mb-5 justify-content-center'>
				{activeCard === -1 ? (
					<>
						<Col lg='4' md='6'>
							<div className='heading-card'>
								<h5 className='heading-card__title'>
									Smart <br /> Contract.
								</h5>
								<span
									className='heading-card__read'
									onClick={() => setActiveCard(1)}
								>
									Get Started{' '}
									<i className='fa fa-arrow-right ml-5'></i>
								</span>
							</div>
						</Col>
						<Col lg='4' md='6' className='mt-4 mt-md-0'>
							<div className='heading-card'>
								<h5 className='heading-card__title'>
									Energy Consumption & Useage
								</h5>
								<span
									className='heading-card__read'
									onClick={() => setActiveCard(2)}
								>
									Get Started{' '}
									<i className='fa fa-arrow-right ml-5'></i>
								</span>
							</div>
						</Col>
						<Col lg='4' md='6' className='mt-4 mt-lg-0'>
							<div className='heading-card'>
								<h5 className='heading-card__title'>
									Current <br /> Challanges
								</h5>
								<span
									className='heading-card__read'
									onClick={() => setActiveCard(3)}
								>
									Get Started{' '}
									<i className='fa fa-arrow-right ml-5'></i>
								</span>
							</div>
						</Col>
					</>
				) : (
					<>
						<Collapse isOpen={activeCard === 1}>
							<SmartContract setActiveCard={setActiveCard} />
						</Collapse>
						<Collapse isOpen={activeCard === 2}>
							<EnergyConsumption setActiveCard={setActiveCard} />
						</Collapse>
						<Collapse isOpen={activeCard === 3}>
							<CurrentChallenge setActiveCard={setActiveCard} />
						</Collapse>
					</>
				)}
			</Row>
		</Container>
	);
}

export default CarbonCreditEcosystem;
