import React from 'react';
import { Container } from 'reactstrap';

function OurContract() {
	return (
		<Container className='text-center mt-7'>
			<h2 className='heading'>Our Contract</h2>
			<div className='contract__address'>
				<a
					target='_blank'
					href='https://bscscan.com/address/0xfB31d8dA5785AeF97D84d31BDAbC784e1768032F'
					className='mb-0'
					rel="noreferrer"
				>
					https://bscscan.com/address/0xfB31d8dA5785AeF97D84d31BDAbC784e1768032F
				</a>
			</div>
		</Container>
	);
}

export default OurContract;
