import React, { useEffect, useRef } from 'react';

function Staking({ setActiveCard }) {
	const stakingRef = useRef();
	const handleClickOutside = e => {
		if (!stakingRef.current?.contains(e.target)) {
			setActiveCard(-1);
		}
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () =>
			document.removeEventListener('mousedown', handleClickOutside);
	});
	return (
		<div className='heading-card__active' ref={stakingRef}>
			<i
				className='fa fa-arrow-left fa-2x text-white pointer'
				onClick={() => {
					setActiveCard(-1);
				}}
			></i>
			<h2 className='mt-5'>STAKING</h2>
			<p className='mt-5'>
				A fair distribution staking reward gives 12% APY to all
				participants who stake their tokens and back the project. This
				system promotes equality and fairness among all community
				members, with everyone getting equal rewards.
				<br />
				<br />
				The current system of staking rewards is not fair. It is not
				equal. It gives an unfair advantage to those with more money,
				and it provides an unfair advantage to those who are more active
				in the community or have early bird access. This doesn't seem
				right. We are upfront and equitable to every investor.
				<br />
				<br />
				A fair distribution staking reward would give 6% APY to all
				community members, regardless of how much money they have or how
				active they are. This would promote equality and fairness and
				give everyone a chance to earn a fair return on their
				investment.
				<br />
				<br />
				As the project continues to grow, the underlying token value is
				expected to rise dramatically, all while you earn a fair,
				consistent return on your staked tokens.
				<br />
				<br />
				We believe that a fair distribution of staking rewards is the
				best way to promote equality and fairness in the community. I
				think it is the best way to give everyone a chance to earn a
				fair return on their investment. I believe that it is the right
				thing to do.
				<br />
				<br />
				<span className='text-site-pink'>Unlike Bitcoin</span> or other
				popular crypto tokens, the PERFUEL token has backing from gold,
				carbon credits, and green company stocks. This mixture of assets
				brings consistent, stable returns and is a real treasury of
				assets under management. PERFUEL is a more stable and reliable
				investment, as the token's value is not solely dependent on the
				volatile cryptocurrency market.
				<br />
				<br />
				<span className='text-site-primary'>PERFUEL</span> is also more
				environmentally friendly, as the carbon credits offset the
				emissions from the token's use. The blockchain is mined
				sustainably, the green company invested in companies benefit,
				and we continue a growth trajectory to become the green
				blockchain of choice. PERFUEL is creating a circular green
				ecosystem where all stops along the green energy trail benefit.
				Backing with real-world assets will continue to provide less
				volatility than many crypto projects with no pegged or
				underlying value.
			</p>
		</div>
	);
}

export default Staking;
