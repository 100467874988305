import React, { useEffect, useRef } from 'react';

function CarbonCreditNFTs({ setActiveCard }) {
	const ccRef = useRef();
	const handleClickOutside = e => {
		if (!ccRef.current?.contains(e.target)) {
			setActiveCard(-1);
		}
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () =>
			document.removeEventListener('mousedown', handleClickOutside);
	});
	return (
		<div className='heading-card__active' ref={ccRef}>
			<i
				className='fa fa-arrow-left fa-2x text-white pointer'
				onClick={() => {
					setActiveCard(-1);
				}}
			></i>
			<h2 className='mt-5'>
				Carbon Credit <br /> NFTs
			</h2>
			<p className='mt-5'>
				Rather than a store of wealth or a piece of unique digital art,
				carbon credit NFTs serve as a repository of information related
				to a specific batch of carbon offsets.
				<br />
				<br />
				This information could include, but is not limited to, the total
				number of offsets (i.e., how many metric tonnes), the vintage
				year of the removal, the project name, the geographical location
				or the certification program utilized.
				<br />
				<br />
				Such NFTs are then fractionalized into Ethereum-based ERC-20
				tokens, fungible with each other.
				<br />
				<br />
				Tokenizing Carbon Credits allows for direct access to capital,
				increased market efficiency, and access to the community
				supporting them. For supporters of carbon credits, it will
				enable them to access the opportunity to make a measurable
				impact and transparent information.
				<br />
				<br />
				<span className='text-site-primary'> PERFUEL</span> is creating
				a new blockchain charity foundation that is dedicated to helping
				support green energy efforts. The foundation was created by a
				group of blockchain enthusiasts who believe in the power of
				technology to change the world. The foundation's goal is to use
				blockchain technology to support green efforts. PERFUEL will
				work with existing charities and non-profit organizations to
				help them use blockchain to improve their work. The foundation
				will also create new programs and initiatives to help people
				transition to renewable green sources.
				<br />
				<br />
				<span className='text-site-primary'>PERFUEL</span> will use
				blockchain to help people in need in several ways. For example,
				the foundation will work with charities to help them accept
				donations in cryptocurrency. This will allow donors to donate
				directly to the charity using their cryptocurrency without going
				through a third party. PERFUEL will also work on developing new
				applications of blockchain technology that can help people in
				need. The foundation is still in its early stages, but the team
				behind PERFUEL is already working on making it a reality.
			</p>
		</div>
	);
}

export default CarbonCreditNFTs;
