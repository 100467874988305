import React, { useEffect, useRef } from 'react';

function GreenMetaverse({ setActiveCard }) {
	const greenRef = useRef();
	const handleClickOutside = e => {
		if (!greenRef.current?.contains(e.target)) {
			setActiveCard(-1);
		}
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () =>
			document.removeEventListener('mousedown', handleClickOutside);
	});
	return (
		<div className='heading-card__active' ref={greenRef}>
			<i
				className='fa fa-arrow-left fa-2x text-white pointer'
				onClick={() => {
					setActiveCard(-1);
				}}
			></i>
			<h2 className='mt-5'>
				Green Metaverse <br /> Initiatives
			</h2>
			<p className='mt-5'>
				The line between the physical and virtual worlds is becoming
				increasingly blurred as the world becomes more digital with a
				foreword vision to the Metaverse. This is particularly true
				regarding the environment, as more people interact with the
				natural world through digital platforms. One of the most
				exciting and essential developments in this area is the rise of
				green metaverse initiatives. PERFUEL aims to create digital
				versions of natural environments to promote conservation and
				sustainable development.
				<br />
				<br />
				PERFUEL has plans to create a 3D virtual world that will be used
				to educate people about the importance of conservation. Another
				important initiative is using virtual reality to raise awareness
				about the plight of endangered species. We plan to explore
				innovative and new ways to approach the Metaverse from a green
				perspective!
				<br />
				<br />
				There is a growing recognition of the potential of digital
				technologies to help protect the environment. As the world
				becomes more digital, we must find ways to use these
				technologies to promote sustainable development. Green metaverse
				initiatives are a promising step in this direction, and we are
				excited to be part of it as it evolves further in the years to
				come.
			</p>
		</div>
	);
}

export default GreenMetaverse;
