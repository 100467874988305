import React, { useEffect, useRef } from 'react';

function SmartContract({ setActiveCard }) {
	const contractRef = useRef();
	const handleClickOutside = e => {
		if (!contractRef.current?.contains(e.target)) {
			setActiveCard(-1);
		}
	};
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () =>
			document.removeEventListener('mousedown', handleClickOutside);
	});
	return (
		<div className='heading-card__active' ref={contractRef}>
			<i
				className='fa fa-arrow-left fa-2x text-white pointer'
				onClick={() => {
					setActiveCard(-1);
				}}
			></i>
			<h2 className='mt-5'>
				Smart <br />
				Contract
			</h2>
			<p className='mt-5'>
				This project's native token, PERFUEL, will be issued in a small
				finite supply of 5 million tokens. The smart contract to mint
				digital tokens, PERFUEL tokens, is based on approved carbon
				credits and investment in various green energy enterprises.{' '}
				<br />
				<br /> The PERFUEL token is a digital asset that can be used to
				pay for environmental projects and can be used to offset carbon
				emissions. The token can be used to support green energy
				projects and can be used to promote sustainable development. The
				token can also be used to finance environmental protection and
				can be used to support eco-friendly initiatives. <br />
				<br /> Our first-of-its-kind revolutionary token, PERFUEL, will
				have backing with real carbon credits. We also are giving back
				to the environment via our charitable grants to help fund
				initiatives decided by the holders of the community! <br />
				<br /> Holders and Stakers of our token will play Dragon's Den
				or Shark Tank with us to decide which green initiatives we
				invest project funds toward for a return to the project and
				distribution among holders. This all creates more active
				engagement and overall investors in the project to participate
				in the vision. We are creating overall value and sustainability.{' '}
				<br />
				<br />
				The future of energy is green. Investing in green energy and
				green energy companies stocks is a smart way to ensure a solid
				return on investment while also doing your part to save the
				planet. Green energy is renewable energy that comes from natural
				sources like the sun, wind, and water. It is also sustainable,
				meaning it can be used repeatedly without damaging the
				environment. There are many reasons to invest in green energy.
				For one, it is good for the environment. Burning fossil fuels
				releases harmful greenhouse gases into the atmosphere,
				contributing to climate change. Green energy doesn't have this
				problem – it's clean and renewable. <br />
				<br /> Another reason to invest in green energy is that it's
				becoming more and more popular. As people become more aware of
				the dangers of climate change, they are increasingly looking for
				ways to reduce their carbon footprint. This means there is a
				growing demand for green energy, which is good news for
				investors. Finally, green energy is simply a wise investment.
				It's estimated that the global market for green energy will
				reach $2.3 trillion by 2020. And, as more countries commit to
				reducing their carbon emissions, the demand for green energy
				will only increase. <br />
				<br /> This makes green energy stocks a safe and profitable
				investment. If you're looking to invest in the future of energy,
				green energy is the way to go. It's good for the environment, in
				demand, and is an intelligent investment. So, consider investing
				in green energy stocks today. <br />
				<br /> Our PERFUEL green-backed crypto exchange is a new type of
				exchange that allows users to trade carbon credits and power
				performance agreements. This exchange is designed to help
				businesses and individuals offset their carbon footprints and
				improve their power performance. The exchange offers a variety
				of benefits, including: <br />
				<br />
				<ul style={{ listStyle: 'Numbers' }}>
					<li>
						A marketplace for companies and individuals to buy and
						sell carbon credits and power performance agreements
					</li>
					<li>A way to offset carbon footprints</li>
					<li>A way to improve power performance</li>
					<li>A way to support green initiative</li>
				</ul>
				<br />
				The PERFUEL exchange offers a variety of benefits that make it
				an attractive option for those looking to reduce their
				environmental impact. <br />
				<br /> PERFUEL aims to be a simple-to-use crypto exchange that
				is easy to understand and navigate. It's a user-friendly
				interface that is easy to navigate. PERFUEL exchange will offer
				a variety of features that are beneficial to users. Some of
				these features include; <br /> <br />
				<ul>
					<li>a variety of currencies to choose from,</li>
					<li>a variety of payment methods,</li>
					<li> a variety of customer support options.</li>
				</ul>
				<br />
				<span className='text-site-primary'>
					The exchange is a central marketplace for carbon credits
				</span>
				<br />
				<br /> The exchange uses a traditional trading architecture and
				distributed ledger technology to securitize carbon credits into
				digital tokens (receipts). This represents a clear line of
				ownership between the carbon credits held in the Trust and the
				digital receipt (Token). <br />
				<br />
				<span className='text-site-primary'>
					{' '}
					Like in traditional commodity exchanges, owners of Tokens
					can request physical delivery of the underlying carbon
					credits.{' '}
				</span>{' '}
				<br />
				<br /> Blockchain allows for an open, transparent, and liquid
				asset that reduces friction and ultimately decentralizes the
				system to provide a truly distributed and borderless solution
				for commodities trading and asset value creation. Blockchain
				technology can provide the foundation to create an efficient and
				100% digital platform that enables the trading and settlement of
				commodities globally and instantly. <br />
				<br /> Because the exchange is blockchainbased, it has allowed
				us to create a hybrid, decentralized platform which mirrors
				commodity markets. The use of blockchain simplifies and
				automates the laborious manual processes that are usually
				involved with OTC carbon trading. The Smart Contract implemented
				makes it easy for users to trade and settle with our system's
				transparent and immutable record. AirCarbon represents the
				commodities market of the future where commodities and
				collateral can be tokenized, creating an entirely new approach
				to securitized token trade
			</p>
		</div>
	);
}

export default SmartContract;
