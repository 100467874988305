import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import {
  setBNBBalance,
  setPerfuelBalance,
  setReferralCode,
  setReferralData,
  setTotalReward,
  setTotalStaked,
  setTotalStakedInTheContract,
  setTotalTokenSold,
  setUserDeposits,
  setWalletAddress,
} from "../store/web3Store";
import { store as web3Store } from "../store/store";
import { configEnv } from "./config";
import Web3Modal from "web3modal";

export let web3 = new Web3(configEnv?.BSC_RPC_URL);

export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
}

export const connectWallet = async () => {
  try {
    const state = web3Store.getState();
    const address = state?.web3Store?.walletAddress;
    if (!address) {
      console.log("Connecting to wallet");
      // /*
      const providerOptions = {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            bridge: "https://bridge.walletconnect.org",
            chainId: configEnv.BSC_CHAINID, //137 for mainnet
            rpc: {
              56: configEnv.BSC_RPC_URL,
              // 97:configEnv.BSC_RPC_URL,
            },
          },
        },
      };

      console.log("Provider is", providerOptions);

      const web3Modal = new Web3Modal({
        cacheProvider: false, // optional
        providerOptions, // required
        disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
      });

      console.log("Web3Modal instance is", web3Modal);
      const provider = await web3Modal.connect();

      provider.on("accountsChanged", async (accounts) => {
        console.log("Accounts", accounts);
        web3Store.dispatch(setWalletAddress(accounts[0]));
        await getUserData(accounts[0]);
      });

      web3 = new Web3(provider);

      console.log("Web3 instance is", web3);

      const chainid = await web3.eth.getChainId();

      console.log(chainid);
      if (chainid !== configEnv?.BSC_CHAINID) {
        alert(`Please connect to ${configEnv.BSC_NETWORK_NAME}`);
        return;
      }
      const accounts = await web3.eth.getAccounts();
      console.log("Acount is", accounts[0]);

      web3Store.dispatch(setWalletAddress(accounts[0]));

      await getUserData(accounts[0]);
    } else {
      console.log("Already connected");
    }
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getGenericData = () => {
  try {
    getTotalTokenSold();
    getTotalStakedInTheContract();
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getUserData = async (walletAddress) => {
  try {
    getUserBNBBalance(walletAddress);
    getPerfuelBalance(walletAddress);
    getTotalReward(walletAddress);
    getTotalOwnedDeposits(walletAddress);
    getReferralCode(walletAddress);
    getReferralData(walletAddress);
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getUserBNBBalance = async (walletAddress) => {
  try {
    const balanceInWei = await web3.eth.getBalance(walletAddress);
    const balanceInEth = parseFloat(web3.utils.fromWei(balanceInWei, "ether"));

    web3Store.dispatch(setBNBBalance(balanceInEth));
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getPerfuelBalance = async (walletAddress) => {
  try {
    const perfuelContract = getPerfuelContract();
    const balanceInWei = await perfuelContract?.methods
      ?.balanceOf(walletAddress)
      ?.call();
    const balanceInEth = parseFloat(balanceInWei) / 10 ** 3;
    web3Store.dispatch(setPerfuelBalance(balanceInEth));
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getTotalTokenSold = async () => {
  try {
    const perfuelPresaleContract = getPerfuelPresaleContract();
    const totalTokenSoldInWei = await perfuelPresaleContract.methods
      .totalTokenSold()
      .call();
    const totalTokenSoldInEth = parseFloat(totalTokenSoldInWei) / 10 ** 3;

    web3Store.dispatch(setTotalTokenSold(totalTokenSoldInEth));
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getTotalOwnedDeposits = async (walletAddress) => {
  try {
    const perfuelStakingContract = getPerfuelStakingContract();

    const ownedDeposits = await perfuelStakingContract?.methods
      ?.getOwnedDeposits(walletAddress)
      .call();

    const depositsData = [];
    let totalStaked = 0;
    for await (let id of ownedDeposits) {
      const depositData = await perfuelStakingContract?.methods
        ?.depositData(id)
        .call();

      depositsData.push(depositData);
      totalStaked += parseFloat(depositData?.amountStaked / 10 ** 3);
    }

    web3Store.dispatch(setTotalStaked(totalStaked));
    web3Store.dispatch(setUserDeposits(depositsData));
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getTotalReward = async (walletAddress) => {
  try {
    const perfuelStakingContract = getPerfuelStakingContract();

    const totalReward =
      parseFloat(
        await perfuelStakingContract?.methods
          ?.getTotalReward(walletAddress)
          .call()
      ) /
      10 ** 3;

    web3Store.dispatch(setTotalReward(totalReward));
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getTotalStakedInTheContract = async () => {
  try {
    const perfuelStakingContract = getPerfuelStakingContract();

    const totalStakedInTheContract =
      parseFloat(await perfuelStakingContract?.methods?.totalStaked().call()) /
      10 ** 3;

    web3Store.dispatch(setTotalStakedInTheContract(totalStakedInTheContract));
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getReferralCode = async (walletAddress) => {
  try {
    const perfuelPresaleContract = getPerfuelReferralContract();
    const referralCode = await perfuelPresaleContract?.methods
      ?.addressToReferral(walletAddress)
      .call();
    web3Store.dispatch(setReferralCode(referralCode));
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getReferralData = async (walletAddress) => {
  try {
    const perfuelPresaleContract = getPerfuelReferralContract();
    const referralData =
        await perfuelPresaleContract?.methods
          ?.referralData(walletAddress)
          .call()

    referralData['totalReward'] = web3.utils.fromWei(referralData['totalReward'],'ether');
    referralData['unClaimedReward'] = web3.utils.fromWei(referralData['unClaimedReward'],'ether');
    referralData['claimedReward'] = web3.utils.fromWei(referralData['claimedReward'],'ether');

    web3Store.dispatch(setReferralData(referralData));
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const getPerfuelContract = () => {
  return new web3.eth.Contract(
    configEnv?.PERFUEL_ABI,
    configEnv?.PERFUEL_TOKEN_SMART_CONTRACT
  );
};

export const getPerfuelPresaleContract = () => {
  return new web3.eth.Contract(
    configEnv?.PERFUEL_PRESALE_ABI,
    configEnv?.PERFUEL_PRESALE_SMART_CONTRACT
  );
};

export const getPerfuelReferralContract = () => {
  return new web3.eth.Contract(
    configEnv?.PERFUEL_REFERRAL_ABI,
    configEnv?.PERFUEL_REFERRAL_SMART_CONTRACT
  );
};

export const getPerfuelMulticallContract = () => {
  return new web3.eth.Contract(
    configEnv?.PERFUEL_MULTICALL_ABI,
    configEnv?.PERFUEL_MULTICALL_SMART_CONTRACT
  );
};

export const getPerfuelStakingContract = () => {
  return new web3.eth.Contract(
    configEnv?.PERFUEL_STAKING_ABI,
    configEnv?.PERFUEL_STAKING_SMART_CONTRACT
  );
};

export const buyToken = async (amount,referral) => {
  try {
    const state = web3Store.getState();
    let referralAddress = 0;
    if (referral) {
      referralAddress = referral;
    }

    console.log("Referral address is", referralAddress);

    const address = state?.web3Store?.walletAddress;

    if (address) {
      const perfuelMulticallContract = getPerfuelMulticallContract();
      const amountInWei = web3.utils.toWei(amount?.toString(), "ether");
      const trx = await perfuelMulticallContract?.methods
        ?.buyTokenWithReferral(referralAddress)
        .send({ from: address, value: amountInWei });

      console.log(trx);

      return trx;
    } else {
      console.log("Please connect your wallet first");
      alert("Please connect your wallet first");
    }
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const stakeToken = async (amount) => {
  try {
    const state = web3Store.getState();

    const address = state?.web3Store?.walletAddress;

    if (address) {
      const perfuelContract = getPerfuelContract();

      const allowance = parseFloat(
        await perfuelContract?.methods
          ?.allowance(address, configEnv?.PERFUEL_STAKING_SMART_CONTRACT)
          .call()
      );

      const perfuelStakingContract = getPerfuelStakingContract();
      const amountInWei = amount * 10 ** 3;
      if (allowance < amountInWei) {
        const trx = await perfuelContract.methods
          .approve(configEnv?.PERFUEL_STAKING_SMART_CONTRACT, amountInWei)
          .send({ from: address });

        console.log("approve trx", trx);
      }

      const trx = await perfuelStakingContract?.methods
        ?.invest(amountInWei)
        .send({ from: address });

      console.log(trx);

      return trx;
    } else {
      console.log("Please connect your wallet first");
      alert("Please connect your wallet first");
    }
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const claimReward = async () => {
  try {
    const state = web3Store.getState();

    const address = state?.web3Store?.walletAddress;

    if (address) {
      const perfuelStakingContract = getPerfuelStakingContract();

      const trx = await perfuelStakingContract?.methods
        ?.withdrawReward()
        .send({ from: address });

      console.log(trx);

      return trx;
    } else {
      console.log("Please connect your wallet first");
      alert("Please connect your wallet first");
    }
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const generateReferralCode = async () => {
  try {
    const state = web3Store.getState();

    const address = state?.web3Store?.walletAddress;
    if (address) {
      const perfuelReferralContract = getPerfuelReferralContract();
      
      const trx = await perfuelReferralContract.methods.createReferal().send({
        from: address,
      });

      return trx;
    }
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};

export const claimReferralReward = async (amount) => {
  try {
    const state = web3Store.getState();
    const address = state?.web3Store?.walletAddress;

    if (address) {
      const perfuelPresaleContract = getPerfuelReferralContract();
      const amountInWei = web3.utils.toWei(amount, "ether");
      const trx = await perfuelPresaleContract?.methods
        ?.claimReward(amountInWei)
        .send({ from: address });

      console.log(trx);

      return trx;
    } else {
      console.log("Please connect your wallet first");
      alert("Please connect your wallet first");
    }
  } catch (err) {
    console.log(err);
    throw new Error(err?.message);
  }
};
