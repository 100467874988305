import Header from '../components/HeroSection/Header';
import TopElipse from '../assets/imgs/Ellipse 12.png';
import BottomElipse from '../assets/imgs/Ellipse 11.png';
const Dashboard = props => {
	return (
		<div className='dashboard position-relative'>
			<img src={TopElipse} alt='' className='img-fluid top-elipse' />
			<Header />

			{props.children}
			<img
				src={BottomElipse}
				alt=''
				className='img-fluid bottom-elipse'
			/>
		</div>
	);
};

export default Dashboard;
