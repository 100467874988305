import React, { useState } from 'react';
import { Container, Row, Col, Collapse } from 'reactstrap';
import Globe from '../assets/imgs/co2-globe.png';
import Clock from '../assets/imgs/clock.png';
import MoneyIcon from '../assets/imgs/carbon-tax.png';
import MoneyCog from '../assets/imgs/money-cof.png';
import Elipse8 from '../assets/imgs/Ellipse 8.png';
function CrabonCredit() {
	const [readMore, setReadMore] = useState(false);
	return (
		<div className='position-relative'>
			<img src={Elipse8} alt='' className='img-fluid globe' />
			<Container className=' px-3 px-md-7 mt-10' fluid>
				<div className='px-0 px-lg-10 text-center'>
					<h2 className='heading'>What is Carbon Credit?</h2>
					<p className='carbon_ecosystem-desc mt-5'>
						In December 2015, the Paris Agreement brought all
						nations into a common cause to undertake ambitious
						efforts to combat climate change. All parties must agree
						to put forward their best efforts through nationally
						determined contributions.
					</p>

					<img src={Globe} alt='' className='img-fluid mt-5' />
				</div>
				<Row className='mt-5 justify-content-center'>
					<Col lg='4' md='6'>
						<div className='carbon_ecosystem__card text-center'>
							<img src={MoneyIcon} alt='' className='img-fluid' />
							<h5 className='mt-6 carbon_ecosystem__card-desc'>
								Introduce a carbon tax where the company pays
								for the CO2 they produce.
							</h5>
						</div>
					</Col>
					<Col lg='4' md='6' className='mt-4 mt-md-0'>
						<div className='carbon_ecosystem__card text-center'>
							<img
								src={Clock}
								alt='clock'
								className='img-fluid'
							/>
							<h5 className='mt-6 carbon_ecosystem__card-desc'>
								Set a specific limit that a company cannot
								exceed.
							</h5>
						</div>
					</Col>
					<Col lg='4' md='6' className='mt-4 mt-lg-0'>
						<div className='carbon_ecosystem__card text-center'>
							<img
								src={MoneyCog}
								alt='clock'
								className='img-fluid'
							/>
							<h5 className='mt-6 carbon_ecosystem__card-desc'>
								implement an emission trading scheme – to create
								a carbon market
							</h5>
						</div>
					</Col>
				</Row>
				<p className='carbon_ecosystem-desc text-center mt-7 px-0 px-lg-10'>
					The final solution has been gaining traction recently
					because of the positive encouragement of clean energy
					producers and incentivizing the Fossil fuel industry to
					become more efficient and gradually reduce its emissions.
					This led to the creation of carbon credits. A carbon credit
					is a tradable permit or certificate that gives the right to
					emit one ton of carbon dioxide or the equivalent of another
					greenhouse gas. There are currently{' '}
					<span className='colored-text'>
						two types of carbon credits:
					</span>
				</p>
				<div className='text-center px-0 px-lg-10'>
					<h4 className='carbon_ecosystem-subheading colored-text mt-8'>
						Voluntary emissions reduction (VER)
					</h4>
					<p className='carbon_ecosystem-desc text-center px-0 px-lg-6'>
						a carbon offset exchanged in the over-the-counter or
						voluntary market for credits,
					</p>
					<h4 className='carbon_ecosystem-subheading colored-text mt-8'>
						Certified emissions reduction (CER)
					</h4>
					<p className='carbon_ecosystem-desc text-center px-0 px-lg-6'>
						emission units (or credits) created through a regulatory
						framework to offset a project's emissions. The main
						difference between the two is that a third-party
						certifying body regulates the CER as opposed to the VER.
					</p>
				</div>
				<div className='card mt-8'>
					<h4 className='card__title'>
						Benefits of Blockchain for Carbon Trading
					</h4>
					<p className='card__desc'>
						Blockchain technology provides a safe, reliable,
						efficient, convenient, open, and inclusive platform
						uniquely suited for implementing Carbon Credit Markets.
						The immutable cryptographicallysecured distributed
						ledger on the blockchain allows for reliable issuance
						and tracking of carbon credits. Public blockchains are
						easily accessible to small and medium-sized enterprises,
						reducing the entry threshold for the carbon trading
						market.
					</p>
					<br />
					<br />
					<Collapse isOpen={readMore}>
						<p className='card__desc'>
							Furthermore, the information provided by companies
							is transparent and accessible to everyone. Recently,
							free automated market makers (AMMs) have been
							developed on blockchains allowing for the trading of
							digitized assets directly on the blockchain without
							intermediary and minimal algorithmic fees. They
							provide the infrastructure required to create a
							digital carbon credit ecosystem and engage all the
							stakeholders.
						</p>
						<br />
						<br />
						<span
							className='text-site-primary pointer'
							onClick={() => setReadMore(!readMore)}
						>
							Read Less <i className='fa fa-arrow-up ml-3'></i>
						</span>
					</Collapse>
					{!readMore && (
						<span
							className='text-site-primary pointer'
							onClick={() => setReadMore(!readMore)}
						>
							Read More <i className='fa fa-arrow-down ml-3'></i>
						</span>
					)}
				</div>
			</Container>
		</div>
	);
}

export default CrabonCredit;
