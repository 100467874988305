import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Container, Label, Button, Row, Col } from "reactstrap";
import { claimReferralReward, getGenericData, getUserData } from "../utils/web3.service";

function Reward() {
  const {
    walletAddress,
    referralData,
  } = useSelector(state=>state?.web3Store)
  
  const [loading,setLoading] = useState(false);

  const claimButtonHandler = async () => {
    try {
      setLoading(true);

      await claimReferralReward(referralData['unClaimedReward']);

      await getUserData(walletAddress);
      await getGenericData();
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      throw new Error(err?.message);
    }
  };

  return (
    <Container className="dashboard__staking mt-3 pb-5">
      <h2 className="text-center text-white mb-5">Rewards Dashboard</h2>
      <Row className="justify-content-center">
        <Col lg="7">
          <div className="dashboard__staking__card d-flex flex-column justify-content-between">
            <div>
              <div className="d-flex">
                <Label className="label">Total Rewards</Label>
                <Label className="balance ml-auto">
                    {parseFloat(referralData["totalReward"] || 0)?.toFixed(4) || 0}
                  <span className="text-site-primary"> BNB</span>
                </Label>
              </div>
              <div className="d-flex">
                <Label className="label">Claimable Rewards</Label>
                <Label className="balance ml-auto">
                {parseFloat(referralData["unClaimedReward"] || 0)?.toFixed(4) || 0} 
                  <span className="text-site-primary"> BNB</span>
                </Label>
              </div>
              <div className="d-flex">
                <Label className="label">Claimed Rewards</Label>
                <Label className="balance ml-auto">
                {parseFloat(referralData["claimedReward"] || 0)?.toFixed(4) || 0}
                  <span className="text-site-primary"> BNB</span>
                </Label>
              </div>
            </div>
            <div>
              <Button className="btn__primary mt-4" block
                disabled={loading}
                onClick={claimButtonHandler}
              >
                {!loading ? "Claim Rewards":"Claiming..."}
                
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Reward;
