import React from 'react';
import { Container } from 'reactstrap';

function LegalDesclaimer() {
	return (
		<Container className='px-2 px-md-5 mt-8' fluid>
			<h2 className='heading text-center'>Legal Disclaimer</h2>
			<p className='mb-0 carbon_ecosystem-desc text-center mt-4'>
				Non-Finanacial Advice - Please Read and Understand
			</p>
			<div className='collapse-card mt-5 mb-5'>
				<h2 className='text-site-pink'>No Investment advice</h2>
				<p className='mt-5'>
					The Content is for informational purposes only; you should
					not construe any such information or other material as
					legal, tax, investment, financial, or other advice. Nothing
					contained on our Site constitutes a solicitation,
					recommendation, endorsement, or offer by IZI or any
					third-party service provider to buy or sell any securities
					or other financial instruments in this or in any other
					jurisdiction in which such solicitation or offer would be
					unlawful under the securities laws of such jurisdiction. All
					Content on this Site is information of a general nature and
					does not address the circumstances of any particular
					individual or entity. Nothing in the Site constitutes
					professional and/or financial advice, nor does any
					information on the Site constitute a comprehensive or
					complete statement of the matters discussed or the law
					relating thereto. HII is not a fiduciary by virtue of any
					person's use of or access to the Site or Content. You alone
					assume the sole responsibility of evaluating the merits and
					risks associated with using any information or other Content
					on the Site before making any decisions based on such
					information or other Content. In exchange for using the
					Site, you agree not to hold HII, its affiliates or any
					third-party service provider liable for any possible claim
					for damages arising from any decision you make based on
					information or other Content made available to you through
					the Site.
				</p>
				<h2 className='mt-5 text-site-pink'>Investment Risks</h2>
				<p className='mt-5'>
					There are risks associated with investing in securities.
					Investing in stocks, bonds, exchangetraded funds, mutual
					funds, and money market funds involves the risk of loss.
					Loss of principal is possible. Some high-risk investments
					may use leverage, which will accentuate gains & losses.
					Foreign investing involves special risks, including a
					greater volatility and political, economic and currency
					risks and differences in accounting methods. A security's or
					a firm's past investment performance is not a guarantee or
					predictor of future investment performance
				</p>
			</div>
		</Container>
	);
}

export default LegalDesclaimer;
