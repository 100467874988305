import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import Frame1 from '../assets/imgs/Frame 107.png';
import TokenomicImg from '../assets/imgs/PERF.png';
function Tokenomics() {
	return (
		<Container className='text-center mt-8' fluid>
			<h2 className='heading'>Tokenomics</h2>
			<p className='carbon_ecosystem-desc'>
				PERFUEL - A decentralized protocol token
			</p>
			<div className='position-relative mt-8'>
				<img src={Frame1} alt='' className='bg-frame img-fluid' />
				<img
					src={TokenomicImg}
					alt=''
					className='img-fluid'
					style={{ zIndex: '1000 !important' }}
				/>
			</div>
			<Row className='mt-7 px-2 px-md-5 justify-content-center'>
				<Col xl='2' lg='3' md='4'>
					<div className='tokenomics-card'>
						<div className='d-flex align-items-center'>
							<div
								style={{
									minHeight: '21px',
									minWidth: '21px',
									background: '#10B6C1',
								}}
							></div>
							<p className='label ml-3 mb-0'>
								Total Team & <br /> Shareholders
							</p>
						</div>
						<h2 className='text-right mt-3 mb-0'>10%</h2>
					</div>
				</Col>
				<Col xl='2' lg='3' md='4' className='mt-4 mt-md-0'>
					<div className='tokenomics-card'>
						<div className='d-flex align-items-center'>
							<div
								style={{
									minHeight: '21px',
									minWidth: '21px',
									background: '#4710B8',
								}}
							></div>
							<p className='label text-left ml-3 mb-0'>
								Carbon Credit <br /> Treasury Assets
							</p>
						</div>
						<h2 className='text-right mt-3 mb-0'>20%</h2>
					</div>
				</Col>
				<Col xl='2' lg='3' md='4' className='mt-4 mt-md-0'>
					<div className='tokenomics-card'>
						<div className='d-flex align-items-center'>
							<div
								style={{
									minHeight: '21px',
									minWidth: '21px',
									background: '#B60A98',
								}}
							></div>
							<p className='label text-left ml-3 mb-0'>
								Investment into Green Blockchain & Metaverse
							</p>
						</div>
						<h2 className='text-right mt-3 mb-0'>20%</h2>
					</div>
				</Col>
				<Col xl='2' lg='3' md='4' className='mt-4 mt-lg-0'>
					<div className='tokenomics-card'>
						<div className='d-flex align-items-center'>
							<div
								style={{
									minHeight: '21px',
									minWidth: '21px',
									background: '#8119B1',
								}}
							></div>
							<p className='label text-left ml-3 mb-0'>
								Gold & Silver & Bitcoin Treasury Assets
							</p>
						</div>
						<h2 className='text-right mt-3 mb-0'>20%</h2>
					</div>
				</Col>
				<Col xl='2' lg='3' md='4' className='mt-4 mt-lg-0'>
					<div className='tokenomics-card'>
						<div className='d-flex align-items-center'>
							<div
								style={{
									minHeight: '21px',
									minWidth: '21px',
									background: '#0A7F88',
								}}
							></div>
							<p className='label ml-3 mb-0'>
								Liquidity Pool <br /> (LP) for Token
							</p>
						</div>
						<h2 className='text-right mt-3 mb-0'>15%</h2>
					</div>
				</Col>
				<Col xl='2' lg='3' md='4' className='mt-4 mt-lg-0'>
					<div className='tokenomics-card'>
						<div className='d-flex align-items-center'>
							<div
								style={{
									minHeight: '21px',
									minWidth: '21px',
									background: '#270076',
								}}
							></div>
							<p className='label text-left ml-3 mb-0'>
								Staking & <br /> Rewards
							</p>
						</div>
						<h2 className='text-right mt-3 mb-0'>15%</h2>
					</div>
				</Col>
			</Row>
		</Container>
	);
}

export default Tokenomics;
